import { createStore } from 'redux';

const totalReducer =
    (
        state = {
            total: 0,
            totalAcoes: 0,
            totalFIIs: 0,
            totalStocks: 0,
            totalETFs: 0
        },
        action
    ) => {
        if (action.type === 'reset') {
            return {
                total: 0,
                totalAcoes: state.totalAcoes,
                totalFIIs: state.totalFIIs,
                totalStocks: state.totalStocks,
                totalETFs: state.totalETFs                
            }
        }
        if (action.type === 'sum') {
            let sum = 0;
            for (let num of action.value) {
                sum += num
            }
            return {
                total: sum,
                totalAcoes: state.totalAcoes,
                totalFIIs: state.totalFIIs,
                totalStocks: state.totalStocks,
                totalETFs: state.totalETFs                
            }
        }
        if (action.type === 'setTotalAcoes') {
            return {
                total: state.total,
                totalAcoes: action.value,
                totalFIIs: state.totalFIIs,
                totalStocks: state.totalStocks,
                totalETFs: state.totalETFs                      
            }
        }
        if (action.type === 'setTotalFIIs') {
            return {
                total: state.total,
                totalAcoes: state.totalAcoes,
                totalFIIs: action.value,
                totalStocks: state.totalStocks,
                totalETFs: state.totalETFs                     
            }
        }
        if (action.type === 'setTotalStocks') {
            return {
                total: state.total,
                totalAcoes: state.totalAcoes,
                totalFIIs: state.totalFIIs,
                totalStocks: action.value,
                totalETFs: state.totalETFs
            }
        }
        if (action.type === 'settotalETFs') {
            return {
                total: state.total,
                totalAcoes: state.totalAcoes,
                totalFIIs: state.totalFIIs,
                totalStocks: state.totalStocks,
                totalETFs: action.value
            }
        }
        return state;
    };

const store = createStore(totalReducer);

export default store;
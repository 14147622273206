import { useSelector } from 'react-redux';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faStar } from "@fortawesome/free-solid-svg-icons";

import classes from "./SummaryTable.module.css"

const brlFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const percentageFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const classForValue = (value, target, tolerancy) => {
  // console.log(value, target, tolerancy);
  const v = target - value;
  if(Math.abs(v) <= tolerancy){
    return 'bg-success'
  }
  if(Math.abs(v) <= tolerancy *2){
    return 'bg-warning'
  }
  return 'bg-danger'
};


const SummaryTable = props => {
  const total = useSelector(state => state.total);
  const totalAcoes = useSelector(state => state.totalAcoes);
  const totalFIIs = useSelector(state => state.totalFIIs);
  const totalStocks = useSelector(state => state.totalStocks);
  const totalETFs = useSelector(state => state.totalETFs);

  const parcelaAcoes = totalAcoes/total;
  const parcelaFIIs = totalFIIs/total;
  const parcelaStocks = totalStocks/total;
  const parcelaETFs = totalETFs/total;

  const tolerancy = 0.029;

  return (
    <>
      <div className="row">
        <div className="col col-md-2"><strong>Total:</strong> {brlFormatter.format(total)}</div>
        <div className={"col col-md-2 text-center " + classes.bgBrasil}>
          BRASIL
        </div>
        <div className={"col col-md-1 " + classes.bgBrasil}>
          {brlFormatter.format(totalAcoes + totalFIIs)}
        </div>
        <div className={"col col-md-2 text-center " + classes.bgEUA}>
          EUA
        </div>
        <div className={"col col-md-1 " + classes.bgEUA}>
          {brlFormatter.format(totalStocks)}
        </div>
        <div className={"col col-md-2 text-center " + classes.bgCripto}>
          CRIPTO
        </div>
        <div className={"col col-md-1 " + classes.bgCripto}>
          R$ 0,00
        </div>
        <div className="col col-md-1"></div>
      </div>

      <div className="row">
        <div className="col col-md-4"></div>
        <div className="col col-md-1">-R$ 956,70</div>
        <div className="col col-md-2"></div>
        <div className="col col-md-1">R$ 85,35</div>
        <div className="col col-md-2"></div>
        <div className="col col-md-1">R$ 0,00</div>
        <div className="col col-md-1"></div>
      </div>

      <div className="row">
        <div className="col col-md-2"></div>
        <div className={"col col-md-1 " + classes.bgRendaFixa}>RENDA FIXA</div>
        <div className={"col col-md-1 " + classes.bgAcoes}>AÇÕES</div>
        <div className={"col col-md-1 " + classes.bgFIIs}>FIIs</div>
        <div className={"col col-md-1 " + classes.bgSTOCKs}>STOCKs</div>
        <div className={"col col-md-1 " + classes.bgETFs}>ETFs</div>
        <div className={"col col-md-1 " + classes.bgREITs}>REITs</div>
        <div className={"col col-md-1 " + classes.bgBITCOIN}>BITCOIN</div>
        <div className={"col col-md-1 " + classes.bgETHEREUM}>ETHEREUM</div>
        <div className={"col col-md-1 " + classes.bgBNB}>BNB</div>
        <div className="col col-md-1"></div>
      </div>

      <div className="row">
        <div className="col col-md-2"></div>
        <div className="col col-md-1">R$ 0,00</div>
        <div className="col col-md-1">{brlFormatter.format(totalAcoes)}</div>
        <div className="col col-md-1">{brlFormatter.format(totalFIIs)}</div>
        <div className="col col-md-1">{brlFormatter.format(totalStocks)}</div>
        <div className="col col-md-1">{brlFormatter.format(totalETFs)}</div>
        <div className="col col-md-1">R$ 0,00</div>
        <div className="col col-md-1">R$ 0,00</div>
        <div className="col col-md-1">R$ 0,00</div>
        <div className="col col-md-1">R$ 0,00</div>
        <div className="col col-md-1"></div>
      </div>

      <div className="row">
        <div className="col col-md-2"></div>
        <div className={"col col-md-1 bg-success " + classes.advice}>10,58%</div>
        <div className={"col col-md-1 "+ classForValue(parcelaAcoes, 0.3, tolerancy) + " "+ classes.advice}>{percentageFormatter.format(totalAcoes / total)}</div>
        <div className={"col col-md-1 "+ classForValue(parcelaFIIs, 0.3, tolerancy) + " "+ classes.advice}>{percentageFormatter.format(totalFIIs / total)}</div>
        <div className={"col col-md-1 "+ classForValue(parcelaStocks, 0.3, tolerancy) + " "+ classes.advice}>{percentageFormatter.format(totalStocks / total)}</div>
        <div className={"col col-md-1 "+ classForValue(parcelaETFs, 0.2, tolerancy) + " "+ classes.advice}>{percentageFormatter.format(totalETFs / total)}</div>
        <div className={"col col-md-1 bg-success " + classes.advice}>0%</div>
        <div className={"col col-md-1 bg-warning " + classes.advice}>0%</div>
        <div className={"col col-md-1 bg-success " + classes.advice}>0%</div>
        <div className={"col col-md-1 bg-success " + classes.advice}>0%</div>
        <div className="col col-md-1"></div>
      </div>

      <div className="row">
        <div className="col col-md-1"></div>
        <div className="col col-md-1">METAS:</div>
        <div className="col col-md-1">10,00%</div>
        <div className="col col-md-1">25,00%</div>
        <div className="col col-md-1">35,00%</div>
        <div className="col col-md-1">20,00%</div>
        <div className="col col-md-1">10,00%</div>
        <div className="col col-md-1">0%</div>
        <div className="col col-md-1">1%</div>
        <div className="col col-md-1">0%</div>
        <div className="col col-md-1">0%</div>
        <div className="col col-md-1"></div>
      </div>

      <div className="row">
        <div className="col col-md-1"></div>
        <div className="col col-md-1">TOLERÂNCIA:</div>
        <div className="col col-md-1">1,00%</div>
        <div className="col col-md-9"></div>
      </div>
    </ >
  )
};

export default SummaryTable;
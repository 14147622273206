import Modal from "./../UI/Modal";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Alert, Container, Form, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose, faCheck } from "@fortawesome/free-solid-svg-icons";

import classes from './ConfirmDialog.module.css';

const ConfirmDialog = (props) => {
    return (
        <Modal modalClass={classes.modal} onClose={props.onCancel}>
            <Form onSubmit={props.onConfirm}>
                <Container className="pt-3">
                    <Row>
                        <Col xs={12}>
                            <Alert key={props.variant} variant={props.variant}>
                                <span className="fw-bolder">{props.header}</span>
                            </Alert>
                            <span>{props.message}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col className={'text-end'} xs={12}>
                            <Button onClick={props.onConfirm} variant="primary" type="button" size="sm" className="m-1">
                                <FontAwesomeIcon icon={faCheck} />&nbsp;Confimar
                            </Button>
                            <Button onClick={props.onCancel} variant="secondary" type="button" size="sm" className="m-1">
                                <FontAwesomeIcon icon={faWindowClose} />&nbsp;Cancelar
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </Modal>
    );
};

export default ConfirmDialog;
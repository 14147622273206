import * as Constants from '../constants';

import { useState } from 'react';

import Modal from "./UI/Modal";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

import classes from "./AssetForm.module.css"

const AssetForm = (props) => {
    const [rowId,] = useState((props.active ? props.active.id : -1));
    const [tickerId, setTickerId] = useState((props.active ? props.active.ticker : props.assets[Object.keys(props.assets)[0]].ticker));
    const [quotas, setQuotas] = useState((props.active ? props.active.quotas : 1));
    const [averagePrice, setAveragePrice] = useState((props.active ? props.active.price : 1));

    const addURL = Constants.ADD_ASSET;
    const editURL = Constants.UPDATE_ASSET;

    const changeTickerHandler = (value) => {
        setTickerId(value);
    };

    const changeQuotasHandler = (value) => {
        setQuotas(value);
    };

    const changeAveragePriceHandler = (value) => {
        setAveragePrice(value);
    };

    const submitAssetHandler = async (event) => {
        event.preventDefault();
        console.log(props.edit, tickerId);

        const assetData = {
            id: rowId,
            ticker: tickerId,
            quotas,
            averagePrice
        };

        const url = (props.edit ? editURL : addURL);
        const response = await fetch(url, {
            method: props.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(assetData)
        });
        props.onFinishSubmit(assetData);
    };

    return (
        <Modal modalClass={classes.modal} onClose={props.onClose}>
            <Form onSubmit={submitAssetHandler}>
                <Container>
                    <Row>
                        <Col xs={2}>
                            <Form.Group className="mb-3" controlId="formBasicName">
                                <Form.Label className="fw-bold pt-1">Ativo:</Form.Label>
                                <Form.Control as="select" value={tickerId} onChange={(e) => { changeTickerHandler(e.target.value) }}>
                                    {
                                        props.assets.map((asset) =>
                                            <option key={asset.id} value={asset.id}>{asset.title}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>

                        <Col xs={4}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label className="fw-bold pt-1">Cotas:</Form.Label>
                                <Form.Control
                                    type="number"
                                    step="1"
                                    value={quotas}
                                    onChange={(e) => changeQuotasHandler(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={4}>
                            <Form.Group className="mb-3" controlId="formBasicMessage">
                                <Form.Label className="fw-bold pt-1">Preço Médio:</Form.Label>
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    value={averagePrice}
                                    onChange={(e) => changeAveragePriceHandler(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        {props.edit &&
                            <Col xs={2}>
                                <Form.Label className="fw-bold pt-1">&nbsp;</Form.Label><br />
                                <Button variant="primary" type="submit" className={classes.assetFormButton}>
                                    <FontAwesomeIcon icon={faFloppyDisk} />&nbsp;Editar
                                </Button>
                            </Col>
                        }
                        {!props.edit &&
                            <Col xs={2}>
                                <Form.Label className="fw-bold pt-1">&nbsp;</Form.Label><br />
                                <Button variant="primary" type="submit" className={classes.assetFormButton}>
                                    <FontAwesomeIcon icon={faFloppyDisk} />&nbsp;Adicionar
                                </Button>
                            </Col>
                        }
                    </Row>
                </Container>
            </Form>
        </Modal>
    );
};

export default AssetForm;
import { PieChart, Pie, Cell, Legend } from 'recharts';

import Quotations from "../../components/Quotations";
import SummaryTable from "./summary/SummaryTable";

// import classes from "./WalletSummary.module.css";

const data = [
    { name: 'Ações', value: 400 },
    { name: 'FIIs', value: 300 },
    // { name: 'Group C', value: 300 },
    // { name: 'Group D', value: 200 },
];

const COLORS = ['#FFD700', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const renderLegend = (props) => {
    const { payload } = props;

    return (
        <ul>
            {payload.map((entry, index) => (
                <li key={`item-${index}`} style={{ listStyleType: "none", marginLeft: "1em" }}>
                    <span style={{ color: entry.color }}>&#10070;</span>&nbsp;&nbsp;{entry.value}
                </li>
            ))}
        </ul>
    );
};

function WalletSummary() {
    // const total = useSelector(state => state.total);

    // const demoUrl = 'https://codesandbox.io/s/pie-chart-with-customized-label-dlhhj';

    // const brlFormatter = new Intl.NumberFormat('pt-BR', {
    //     style: 'currency',
    //     currency: 'BRL',
    // });

    return <>
        <div className="row">
            <div className="col col-md-2">

            </div>
            <div className="col col-md-10">

            </div>
        </div>
        <div className="row">
            <div className="col col-md-10">
                <SummaryTable />
                <div className='row'>
                    {/* {brlFormatter.format(total)} */}

                    <PieChart width={250} height={250}>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Legend content={renderLegend} />
                    </PieChart>
                </div>
            </div>
            <div className="col col-md-2">
                <Quotations />
            </div>
        </div>
    </>
}

export default WalletSummary;
import * as Constants from '../../constants';

import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import classes from "./WalletAcoesList.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faExternalLink, faPlusCircle, faFileDownload, faFileUpload } from "@fortawesome/free-solid-svg-icons";

import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';

import AssetForm from '../../components/AssetForm';
import ScrollContainer from '../../components/UI/ScrollContainer';
import ConfirmDialog from '../../components/UI/ConfirmDialog';

import { CSVLink } from "react-csv";
import Papa from 'papaparse';

const brlFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const percentageFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const defaultFormAsset = { id: 0, ticker: 0, quotas: 0, price: 0 };

function WalletAcoesList(props) {
  const csvUploadFileRef = useRef();
  const total = useSelector(state => state.total);

  const [acoes, setAcoes] = useState([]);
  const [totalAcoes, setTotalAcoes] = useState(0);
  const [totalInvestidoAcoes, setTotalInvestidoAcoes] = useState(0);
  const [rentabilidadeTotal, setRentabilidadeTotal] = useState(0);
  const [formIsShown, setFormIsShown] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [deleteConfirmationIsShown, setDeleteConfirmationIsShown] = useState(false);
  const [deleteWalletConfirmationIsShown, setDeleteWalletConfirmationIsShown] = useState(false);
  const [selectedWalletItem, setSelectedWalletItem] = useState(defaultFormAsset);

  const finishSubmitAssetHandler = async () => {
    await fetchAcoesHandler();
    hideFormHandler();
  };

  const hideFormHandler = () => {
    setFormIsShown(false);
  };

  const deleteConfirmationHandler = async () => {
    props.setIsLoading(true);
    try {
      const response = await fetch(Constants.DELETE_ASSET, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "id": selectedWalletItem.id })
      });

      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
    } catch (error) {
    }
    setSelectedWalletItem({});
    setDeleteConfirmationIsShown(false);
    fetchAcoesHandler();
    props.onUpdateAssets(totalAcoes);
    props.setIsLoading(false);
  };

  const hideDeleteConfirmationHandler = () => {
    setDeleteConfirmationIsShown(false);
  };

  const hideDeleteWalletConfirmationHandler = () => {
    setDeleteWalletConfirmationIsShown(false);
  };


  const deleteHandler = (id) => {
    const title = acoes.filter((el) => { return el.id === id })[0].codigo;
    setSelectedWalletItem({ id, title });
    setDeleteConfirmationIsShown(true);
  };

  const addHandler = (id) => {
    setSelectedWalletItem(defaultFormAsset);
    setEditMode(false);
    setFormIsShown(true);
  };


  const deleteWalletConfirmationHandler = async () => {
    props.setIsLoading(true);
    try {
      const response = await fetch(Constants.DELETE_WALLET, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "class": 1 })
      });

      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
    } catch (error) {
    }
    setDeleteWalletConfirmationIsShown(false);
    fetchAcoesHandler();
    props.onUpdateAssets(totalAcoes);
    props.setIsLoading(false);
  };

  const deleteWalletHandler = () => {
    // const title = acoes.filter((el) => { return el.id === id })[0].codigo;
    setDeleteWalletConfirmationIsShown(true);
  };

  const submitAssetListHandler = async (data) => {
    console.log(data);
    let pData = [];
    data.forEach(asset => {
      const assetData = {
        id: asset[1],
        quotas: asset[0],
        averagePrice: asset[2]
        // averagePrice: asset[2].replace(",", ".")
      };
      pData.push(assetData);
    });
    console.log(pData);
    const listData = {
      class: 1,
      data: pData
    }

    const url = Constants.ADD_ASSET_LIST
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(listData)
    });
    console.log(response);
    // onFinishSubmit(pData);
  };

  const uploadButtonHandler = () => {
    csvUploadFileRef.current.click();
  };

  const csvUploadHandler = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    // console.log(fileObj);

    Papa.parse(fileObj, {
      skipEmptyLines: true,
      complete: function (results) {
        // for (const entry of Object.entries(results.data)) {
        console.log(results);
        submitAssetListHandler(results.data);
        // }
      }
    });

    fetchAcoesHandler();
  };

  const editHandler = (id) => {
    const filtered = acoes.filter((el) => { return el.id === id })[0];
    const selected = props.assets.filter((el) => { return el.title === filtered.codigo })[0];

    setSelectedWalletItem({
      id,
      ticker: Number(selected.id),
      quotas: Number(filtered.cotas),
      price: Number(filtered.price)
    });
    setEditMode(true);
    setFormIsShown(true);
  };

  const fetchAcoesHandler = useCallback(async () => {
    props.setIsLoading(true);
    let rentabilidade = 0;
    try {
      const response = await fetch(Constants.WALLET_ACOES_LIST);
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }

      const data = await response.json();
      let sum = 0;
      let sumInvestido = 0;
      const loadedAcoes = [];

      for (const item in data) {
        const i = data[item];
        let investido = i.price * i.quantity;
        let atual = i.currentPrice * i.quantity;

        let acao = {
          id: i.id,
          codigo: i.ticker,
          ticker: item,
          cotas: i.quantity,
          setor: i.sector,
          subsetor: i.subsector,
          price: i.price,
          currentPrice: i.currentPrice,
          valorInvestido: investido,
          valorAtual: atual,
          rentabilidadePercentual: (atual - investido) / investido,
          rentabilidadeAbsoluta: (atual - investido),
          parcelaTotalGeral: atual,
          mL: i.mL,
          pvp: i.pvp,
          d: i.d,
          dy: i.dy,
          yoc: ((Number(i.d.replace(",", ".")) / Number(i.price) * 100).toFixed(2)),
          vD: i.vD,
          vM: i.vM,
          vY: i.vY
        }
        sum += acao.valorAtual;
        sumInvestido += acao.valorInvestido;
        rentabilidade += acao.rentabilidadeAbsoluta;
        console.log(acao);
        loadedAcoes.push(acao);
      }

      setTotalAcoes(sum);
      setTotalInvestidoAcoes(sumInvestido);
      setAcoes(loadedAcoes);
      setRentabilidadeTotal(rentabilidade);
      props.onUpdateAssets(sum);
      props.setIsLoading(false);
    } catch (error) {
    }
    props.setIsLoading(false);
  }, [total, acoes]);

  useEffect(() => {
    fetchAcoesHandler();
  }, []);

  useEffect(() => {
    props.onUpdateAssets(totalAcoes);
    props.setIsLoading(false);
    // updateRentabilidade();
  }, [total, totalAcoes, acoes]);

  const csvDownloadHandler = () => {
    let acoesCopy = [];
    acoes.forEach(object => {
      const obj = {
        cotas: object.cotas,
        codigo: object.codigo,
        price: object.price,
        id: object.id
      }
      acoesCopy.push(obj);
    });
    return acoesCopy;
  }

  const confirmDeleteMessage = <>Tem certeza que deseja excluir o ativo <b>{selectedWalletItem.title}</b>?</>;
  const confirmDeleteWalletMessage = <>Tem certeza que deseja excluir todos os ativos?</>;

  return <>
    {formIsShown && <AssetForm
      edit={editMode}
      active={selectedWalletItem}
      assets={props.assets}
      method="POST"
      onFinishSubmit={finishSubmitAssetHandler}
      onClose={hideFormHandler}
    />}
    {deleteConfirmationIsShown && <ConfirmDialog
      variant="warning"
      onConfirm={deleteConfirmationHandler}
      onCancel={hideDeleteConfirmationHandler}
      header="Atenção!"
      message={confirmDeleteMessage}
    />}
    {deleteWalletConfirmationIsShown && <ConfirmDialog
      variant="danger"
      onConfirm={deleteWalletConfirmationHandler}
      onCancel={hideDeleteWalletConfirmationHandler}
      header="Atenção!"
      message={confirmDeleteWalletMessage}
    />}
    {!props.isLoading &&
      <>
        <div className='row'>
          <div className='col col-md-4'>
            <Button onClick={addHandler} variant="primary" type="button" size="sm" className="m-1">
              <FontAwesomeIcon icon={faPlusCircle} />&nbsp;Adicionar
            </Button>

            <Button onClick={uploadButtonHandler} variant="outline-primary" type="button" size="sm" className="m-1" title="Enviar CSV">
              <input
                ref={csvUploadFileRef}
                onChange={csvUploadHandler}
                type="file"
                accept="text/csv"
                style={{ display: "none" }}
              />
              <FontAwesomeIcon icon={faFileUpload} />
            </Button>

            <CSVLink data={csvDownloadHandler()} filename={"acoes.csv"}>
              <Button variant="outline-success" type="button" size="sm" className="m-1" title="Baixar CSV">
                <FontAwesomeIcon icon={faFileDownload} />
              </Button>
            </CSVLink>

            <Button onClick={deleteWalletHandler} variant="outline-danger" type="button" size="sm" className="m-1">
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>

          <div className='col col-md-2'>
            <span className="fw-bold">Total Geral:</span> {isNaN(total) ? 0 : brlFormatter.format(total)}
          </div>

          <div className='col col-md-2'>
            <span className="fw-bold">Total Investido em Ações:</span> {brlFormatter.format(totalInvestidoAcoes)}
          </div>

          <div className='col col-md-2'>
            <span className="fw-bold">Total em Ações:</span> {brlFormatter.format(totalAcoes)} <small>({isNaN(totalAcoes) || totalAcoes === 0 ? '0%' : percentageFormatter.format(totalAcoes / total)})</small>
          </div>

          <div className="col col-md-2">
            <span className={(rentabilidadeTotal >= 0 ? 'fw-bold positive' : 'fw-bold negative')}>Rentabilidade:</span> {brlFormatter.format(rentabilidadeTotal)} <small>({isNaN(rentabilidadeTotal) || rentabilidadeTotal === 0 ? '0%' : percentageFormatter.format(rentabilidadeTotal / totalAcoes)})</small>
          </div>
        </div>

        <ScrollContainer>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th rowSpan="2" className={classes.th}>Cotas</th>
                <th rowSpan="2" className={classes.th}>Código</th>
                <th rowSpan="2" className={classes.th}>Setor<br />Subsetor</th>
                <th rowSpan="2" className={classes.th}>Margem<br />Líquida</th>
                <th rowSpan="2" className={classes.th}>Dívida<br />Líquida<br />/PL</th>
                <th rowSpan="2" className={classes.th}>PVP</th>
                <th colSpan="3" className={classes.th}>Dividendos</th>
                {/* <th rowSpan="2" className={classes.th}>Dividend<br />Yield</th> */}
                {/* <th rowSpan="2" className={classes.th}>Último<br />Dividendo</th> */}
                <th colSpan="3" className={classes.th}>Variação</th>
                <th colSpan="2" className={classes.th}>Preço</th>
                <th colSpan="2" className={classes.th}>Valor</th>
                <th rowSpan="2" className={classes.th}>%<br />Geral</th>
                <th colSpan="2" className={classes.th}>Rentabilidade</th>
                <th rowSpan="2" className={classes.th}>Operações</th>
              </tr>
              <tr>
                <th className={classes.th}>YOC</th>
                <th colSpan="2" className={classes.th}>12 Meses</th>
                {/* <th className={classes.th}>1 Mês</th> */}
                <th className={classes.th}>1 Dia</th>
                <th className={classes.th}>1 Mês</th>
                {/* <th className={classes.th}>2023</th> */}
                <th className={classes.th}>1 Ano</th>
                <th className={classes.th}>Médio</th>
                <th className={classes.th}>Atual</th>
                <th className={classes.th}>Investido</th>
                <th className={classes.th}>Atual</th>
                <th className={classes.th}>%</th>
                <th className={classes.th}>R$</th>
              </tr>
            </thead>
            <tbody>
              {acoes.length === 0 && <tr>
                <td colSpan='20'>Nenhum ativo cadastrado.</td>
              </tr>
              }
              {
                acoes.map((acao) => <tr key={acao.id}>
                  <td className={classes.td}>{acao.cotas}</td>
                  <td className={classes.td + " text-center"}>
                    <span className="font-weight-bold">{acao.ticker}</span><br />
                    <a href={`${Constants.BASE_URL_BRA_ACAO_STATUSINVEST}${acao.ticker}`} target='_blank' rel="noreferrer" title='StatusInvest'>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>&nbsp;&nbsp;
                    <a href={`${Constants.BASE_URL_BRA_ACAO_INFOMONEY}${acao.ticker}`} target='_blank' rel="noreferrer" title='InfoMoney'>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>
                  </td>
                  <td className={classes.td}>{acao.setor}<br />{acao.subsetor}</td>
                  <td className={classes.td}>{percentageFormatter.format(acao.mL / 100)}</td>
                  <td className={classes.td}>{acao.payout}%</td>
                  {/* <td className={classes.td + "" + (acao.pvp < 1 ? " text-success" : "")}>{acao.pvp.replace(".", ",")}</td> */}
                  <td className={classes.td + "" + (acao.pvp < 1 ? " text-success" : "")}>{acao.pvp}</td>
                  <td className={classes.td + "" + (acao.yoc < acao.dy ? " text-danger" : "")}>{percentageFormatter.format(acao.yoc / 100)}</td>
                  <td className={classes.td}>{percentageFormatter.format(acao.dy / 100)}</td>
                  {/* <td className={classes.td}>R$ {acao.d.replace(".", ",")}</td> */}
                  <td className={classes.td}>R$ {acao.d}</td>
                  <td className={classes.td + "" + (acao.vD < 0 ? " text-danger" : "")}>{percentageFormatter.format(acao.vD / 100)}</td>
                  <td className={classes.td + "" + (acao.vM < 0 ? " text-danger" : "")}>{percentageFormatter.format(acao.vM / 100)}</td>
                  {/* <td className={classes.td}>{acao.vYTD}%</td> */}
                  <td className={classes.td + "" + (acao.vY < 0 ? " text-danger" : "")}>{percentageFormatter.format(acao.vY / 100)}</td>
                  <td className={classes.td + (acao.price > acao.currentPrice ? " text-danger" : "")}>{brlFormatter.format(acao.price)}</td>
                  <td className={classes.td}>{brlFormatter.format(acao.currentPrice)}</td>
                  <td className={classes.td + (acao.valorInvestido > acao.valorAtual ? " text-danger" : "")}>{brlFormatter.format(acao.valorInvestido)}</td>
                  <td className={classes.td}>{brlFormatter.format(acao.valorAtual)}</td>
                  <td className={classes.td + "" + (acao.parcelaTotalGeral / total > 0.02 ? " text-danger" : "")}>{percentageFormatter.format(acao.parcelaTotalGeral / total)}</td>
                  <td className={classes.td + (acao.rentabilidadePercentual < 0 ? " text-danger" : "")}>{percentageFormatter.format(acao.rentabilidadePercentual)}</td>
                  <td className={classes.td + (acao.rentabilidadeAbsoluta < 0 ? " text-danger" : "")}>{brlFormatter.format(acao.rentabilidadeAbsoluta)}</td>
                  <td className={classes.td} style={{ textAlign: "center" }}>
                    <Link onClick={() => editHandler(acao.id)}><FontAwesomeIcon icon={faEdit} /></Link>&nbsp;&nbsp;&nbsp;
                    <Link onClick={() => deleteHandler(acao.id)}><FontAwesomeIcon icon={faTrash} /></Link>
                  </td>
                </tr>
                )
              }
            </tbody>
          </Table>
        </ScrollContainer>
      </>
    }
  </>
}

export default WalletAcoesList;

import Quotations from "../components/Quotations";

function HomePage() {
    return <>
        <div className="row">
            <div className="col col-md-2">
                <Quotations title="Cotações"/>
            </div>
        </div>
    </>
}

export default HomePage;
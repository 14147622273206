import * as Constants from '../../constants';

import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function LoginForm(props) {
    const navigation = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const isSubmitting = navigation.state === "submitting";

    async function handleSubmit(event) {
        event.preventDefault();
        // console.log(email, password);

        const response = await fetch(Constants.LOGIN, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
        });

        if (response.status === 422 || response.status === 401) {
            // return response;
            console.log('422 ou 401');
        }

        if (!response.ok) {
            // throw json({ message: "Could not authenticate user." }, { status: 500 });
            console.log('Não ok');
        }

        if (response.ok) {
            // TODO: gerenciar token
            const data = await response.json();
            if (data.token) {
                props.onLogin(data);

                navigation('/wallet')
            } else {
                setErrorMessage("Could not login!");
                setShowError(true);
            }
        } else {
            setErrorMessage("Server failed!");
            setShowError(true);
        }
    }

    return (
        <Container className="mt-5">
            <Form method="post" onSubmit={handleSubmit}>
                <Form.Group className="mb-1" controlId="formBasicEmail">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <Form.Label>Email:</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={event => setEmail(event.target.value)}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Form.Group>

                <Form.Group className="mb-1" controlId="formBasicPassword">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <Form.Label>Password:</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={event => setPassword(event.target.value)}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Form.Group>

                <Form.Group className="mb-1" controlId="formBottom">
                    <Container>
                        <Row>
                            <Col xs={1}>
                                <Button variant="primary" type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "Submitting..." : "Login"}
                                </Button>
                            </Col>
                            <Col xs={11}>
                                {showError && <p>{errorMessage}</p>}
                            </Col>
                        </Row>
                    </Container>
                </Form.Group>
            </Form>
        </Container>
    );
}

export default LoginForm;
import Card from "./UI/Card";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import classes from "./Quotations.module.css"

const QUOTATIONS = [
    { id: "usd", title: "Dólar", value: "5,1503" },
    { id: "eur", title: "Euro", value: "6,7106" },
    { id: "btc", title: "Bitcoin", value: "146.023,37" },
];

const Quotations = props => {
    return (
        <Card>
            <h4 className={classes.title}><FontAwesomeIcon icon={faStar} />&nbsp;{props.title}</h4>
            <ul>
                {QUOTATIONS.map(quotation =>
                    <li key={quotation.id}>
                        <span><strong>{quotation.title}</strong></span>
                        <span className={classes.right}>R$ {quotation.value}</span>
                    </li>
                )}
            </ul>
        </Card>
    )
};

export default Quotations;

import { Link, useNavigate, useMatch, useResolvedPath } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket, faHome, faWallet, faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import Flag from 'react-world-flags';

function MainNavigation(props) {
    const navigate = useNavigate();

    const loginHanlder = () => {
        navigate("/login");
    };

    const bra = <Flag code="bra" height="15" />;
    const eua = <Flag code="usa" height="13" />;

    return <nav className="mainNavigation">
        <Link to="/" className="site-title"><FontAwesomeIcon icon={faRocket} />&nbsp;Investidor Libertário</Link>
        <ul>
            <CustomLink to="/">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faHome} />&nbsp;Início&nbsp;</CustomLink>
            <CustomLink to="/acoes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{bra}&nbsp;Ações&nbsp;</CustomLink>
            <CustomLink to="/fiis">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{bra}&nbsp;FIIs&nbsp;</CustomLink>
            <CustomLink to="/stocks">&nbsp;&nbsp;&nbsp;&nbsp;{eua}&nbsp;Stocks&nbsp;</CustomLink>
            <CustomLink to="/etfs">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{eua}&nbsp;ETFs&nbsp;</CustomLink>
            <CustomLink to="/reits" className="onlyIcon">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{eua}&nbsp;REITs&nbsp;</CustomLink>
            <CustomIconLink to="/tools">&nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faScrewdriverWrench} /></CustomIconLink>
            {props.isLoggedIn && <CustomLink to="/wallet">&nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faWallet} />&nbsp;Carteira&nbsp;</CustomLink>}
            {!props.isLoggedIn && <Button variant="success" onClick={loginHanlder}>&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRightToBracket} />&nbsp;Login&nbsp;</Button>}
            {/* {!props.isLoggedIn && <CustomLink to="/login">&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRightToBracket} />&nbsp;Login&nbsp;</CustomLink>} */}
            {/* {props.isLoggedIn && <CustomLink to="/logout">&nbsp;<FontAwesomeIcon icon={faArrowRightToBracket} />&nbsp;Logout&nbsp;</CustomLink>} */}
            {props.isLoggedIn && <Button variant="danger" onClick={props.onLogout}>&nbsp;<FontAwesomeIcon icon={faArrowRightToBracket} />&nbsp;Logout&nbsp;</Button>}
        </ul>
    </nav>
}

export default MainNavigation;

function CustomIconLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
    return (
        <li className={isActive ? "active onlyIcon" : "onlyIcon"}>
            <Link to={to} {...props}>{children}</Link>
        </li>
    )
}

function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>{children}</Link>
        </li>
    )
}
import * as Constants from '../../constants';

import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import classes from "./WalletStocksList.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faExternalLink, faPlusCircle, faFileDownload, faFileUpload } from "@fortawesome/free-solid-svg-icons";

import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';

import AssetForm from '../../components/AssetForm';
import ScrollContainer from '../../components/UI/ScrollContainer';
import ConfirmDialog from '../../components/UI/ConfirmDialog';

import { CSVLink } from "react-csv";
import Papa from 'papaparse';

const brlFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const usdFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol'
});

const percentageFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const defaultFormAsset = { id: 1, quotas: 1, price: 1 };

function WalletStocksList(props) {
  const csvUploadFileRef = useRef();
  const total = useSelector(state => state.total);

  const [stocks, setStocks] = useState([]);
  const [totalStocks, setTotalStocks] = useState(0);
  const [totalInvestidoStocks, setTotalInvestidoStocks] = useState(0);
  const [rentabilidadeTotal, setRentabilidadeTotal] = useState(0);
  const [formIsShown, setFormIsShown] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [deleteConfirmationIsShown, setDeleteConfirmationIsShown] = useState(false);
  const [deleteWalletConfirmationIsShown, setDeleteWalletConfirmationIsShown] = useState(false);
  const [selectedWalletItem, setSelectedWalletItem] = useState(defaultFormAsset);

  const assetSubmitHandler = async () => {
    await fetchStocksHandler();
    hideFormHandler();
  };

  const hideFormHandler = () => {
    setFormIsShown(false);
  };

  const deleteConfirmationHandler = async () => {
    props.setIsLoading(true);
    try {
      const response = await fetch(Constants.DELETE_ASSET, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "id": selectedWalletItem.id })
      });

      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
    } catch (error) {
    }
    setSelectedWalletItem({});
    setDeleteConfirmationIsShown(false);
    fetchStocksHandler();
    props.onUpdateAssets(totalStocks);
    props.setIsLoading(false);
  };

  const hideDeleteConfirmationHandler = () => {
    setDeleteConfirmationIsShown(false);
  };

  const hideDeleteWalletConfirmationHandler = () => {
    setDeleteWalletConfirmationIsShown(false);
  };


  const deleteHandler = (id) => {
    const title = stocks.filter((el) => { return el.id === id })[0].codigo;
    setSelectedWalletItem({ id, title });
    setDeleteConfirmationIsShown(true);
  };

  const addHandler = (id) => {
    setSelectedWalletItem(defaultFormAsset);
    setEditMode(false);
    setFormIsShown(true);
  };


  const deleteWalletConfirmationHandler = async () => {
    props.setIsLoading(true);
    try {
      const response = await fetch(Constants.DELETE_WALLET, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "class": 3 })
      });

      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
    } catch (error) {
    }
    setDeleteWalletConfirmationIsShown(false);
    fetchStocksHandler();
    props.onUpdateAssets(totalStocks);
    props.setIsLoading(false);
  };

  const deleteWalletHandler = () => {
    // const title = acoes.filter((el) => { return el.id === id })[0].codigo;
    setDeleteWalletConfirmationIsShown(true);
  };

  const submitAssetListHandler = async (data) => {
    console.log(data);
    let pData = [];
    data.forEach(asset => {
      const assetData = {
        id: asset[1],
        quotas: asset[0],
        averagePrice: asset[2].replace(",", ".")
      };
      pData.push(assetData);
    });
    console.log(pData);
    const listData = {
      class: 3,
      data: pData
    }

    const url = Constants.ADD_ASSET_LIST;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(listData)
    });
    console.log(response);
    // onFinishSubmit(pData);
  };

  const uploadButtonHandler = () => {
    csvUploadFileRef.current.click();
  };

  const csvUploadHandler = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    // console.log(fileObj);

    Papa.parse(fileObj, {
      skipEmptyLines: true,
      complete: function (results) {
        // for (const entry of Object.entries(results.data)) {
        console.log(results);
        submitAssetListHandler(results.data);
        // }
      }
    });

    fetchStocksHandler();
  };

  const editHandler = (id) => {
    const filtered = stocks.filter((el) => { return el.id === id })[0];
    const selected = props.assets.filter((el) => { return el.title === filtered.codigo })[0];

    setSelectedWalletItem({
      id,
      ticker: Number(selected.id),
      quotas: Number(filtered.cotas),
      price: Number(filtered.price)
    });
    setEditMode(true);
    setFormIsShown(true);
  };

  const fetchStocksHandler = useCallback(async () => {
    props.setIsLoading(true);
    let rentabilidade = 0;
    try {
      const response = await fetch(Constants.WALLET_STOCKS_LIST);
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }

      const data = await response.json();

      let sum = 0;
      let sumInvestido = 0;
      const loadedStocks = [];
      for (const key in data) {
        let investido = data[key].price * data[key].quantity;
        let atual = data[key].currentPrice * data[key].quantity;

        let stock = {
          id: data[key].id,
          codigo: data[key].title,
          cotas: data[key].quantity,
          setor: data[key].sector,
          subsetor: data[key].subsector,
          price: data[key].price,
          currentPrice: data[key].currentPrice,
          valorInvestido: investido,
          valorAtual: atual,
          rentabilidadePercentual: (atual - investido) / investido,
          rentabilidadeAbsoluta: (atual - investido),
          parcelaTotalGeral: atual
        }
        const indicators = data[key].indicators;
        for (const i in indicators) {
          stock[indicators[i].indicator] = indicators[i].value;
        }
        stock['yoc'] = ((Number(stock.d.replace(",", ".")) / Number(stock.price) * 100).toFixed(2));

        sum += stock.valorAtual;
        sumInvestido += stock.valorInvestido;
        rentabilidade += stock.rentabilidadeAbsoluta;
        loadedStocks.push(stock);
      }
      setTotalStocks(sum);
      setTotalInvestidoStocks(sumInvestido);
      setStocks(loadedStocks);
      setRentabilidadeTotal(rentabilidade);
      props.onUpdateAssets(sum);
      props.setIsLoading(false);
    } catch (error) {
    }
    props.setIsLoading(false);
  }, [total, stocks]);

  useEffect(() => {
    fetchStocksHandler();
  }, []);

  useEffect(() => {
    const avgDollar = localStorage.getItem("avgDollar");
    props.onUpdateAssets(totalStocks*avgDollar);
    props.setIsLoading(false);
    // updateRentabilidade();
  }, [total, totalStocks, stocks]);

  const csvDownloadHandler = () => {
    let acoesCopy = [];
    stocks.forEach(object => {
      const obj = {
        cotas: object.cotas,
        codigo: object.codigo,
        price: object.price,
        id: object.id
      }
      acoesCopy.push(obj);
    });
    return acoesCopy;
  }

  const confirmDeleteMessage = <>Tem certeza que deseja excluir o ativo <b>{selectedWalletItem.title}</b>?</>;
  const confirmDeleteWalletMessage = <>Tem certeza que deseja excluir todos os ativos?</>;

  return <>
    {formIsShown && <AssetForm
      edit={editMode}
      active={selectedWalletItem}
      assets={props.assets}
      method="POST"
      onFinishSubmit={assetSubmitHandler}
      onClose={hideFormHandler}
    />}
    {deleteConfirmationIsShown && <ConfirmDialog
      variant="warning"
      onConfirm={deleteConfirmationHandler}
      onCancel={hideDeleteConfirmationHandler}
      header="Atenção!"
      message={confirmDeleteMessage}
    />}
    {deleteWalletConfirmationIsShown && <ConfirmDialog
      variant="danger"
      onConfirm={deleteWalletConfirmationHandler}
      onCancel={hideDeleteWalletConfirmationHandler}
      header="Atenção!"
      message={confirmDeleteWalletMessage}
    />}

    {!props.isLoading &&
      <>
        <div className='row'>
          <div className='col col-md-4'>
            <Button onClick={addHandler} variant="primary" type="button" size="sm" className="m-1">
              <FontAwesomeIcon icon={faPlusCircle} />&nbsp;Adicionar
            </Button>

            <Button onClick={uploadButtonHandler} variant="outline-primary" type="button" size="sm" className="m-1" title="Enviar CSV">
              <input
                ref={csvUploadFileRef}
                onChange={csvUploadHandler}
                type="file"
                accept="text/csv"
                style={{ display: "none" }}
              />
              <FontAwesomeIcon icon={faFileUpload} />
            </Button>

            <CSVLink data={csvDownloadHandler()} filename={"stocks.csv"}>
              <Button variant="outline-success" type="button" size="sm" className="m-1" title="Baixar CSV">
                <FontAwesomeIcon icon={faFileDownload} />
              </Button>
            </CSVLink>

            <Button onClick={deleteWalletHandler} variant="outline-danger" type="button" size="sm" className="m-1">
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>

          <div className='col col-md-2'>
            <span className="fw-bold">Total Geral:</span> {isNaN(total) ? 0 : brlFormatter.format(total)}
          </div>

          <div className='col col-md-2'>
            <span className="fw-bold">Total Investido em Stocks:</span> {usdFormatter.format(totalInvestidoStocks)}
          </div>

          <div className='col col-md-2'>
            <span className="fw-bold">Total em Stocks:</span> {usdFormatter.format(totalStocks)} <small>({isNaN(totalStocks) || totalStocks === 0 ? '0%' : percentageFormatter.format(totalStocks*localStorage.getItem("avgDollar") / total)})</small>
          </div>

          <div className="col col-md-2">
            <span className={(rentabilidadeTotal >= 0 ? 'fw-bold positive' : 'fw-bold negative')}>Rentabilidade:</span> {usdFormatter.format(rentabilidadeTotal)} <small>({isNaN(rentabilidadeTotal) || rentabilidadeTotal === 0 ? '0%' : percentageFormatter.format(rentabilidadeTotal / totalStocks)})</small>
          </div>
        </div>

        <ScrollContainer>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th rowSpan="2" className={classes.th}>Cotas</th>
                <th rowSpan="2" className={classes.th}>Código</th>
                <th rowSpan="2" className={classes.th}>Setor<br />Subsetor</th>
                <th rowSpan="2" className={classes.th}>Margem<br />Líquida</th>
                <th rowSpan="2" className={classes.th}>Payout</th>
                <th rowSpan="2" className={classes.th}>PVP</th>
                <th colSpan="3" className={classes.th}>Dividendos</th>
                {/* <th rowSpan="2" className={classes.th}>Dividend<br />Yield</th> */}
                {/* <th rowSpan="2" className={classes.th}>Último<br />Dividendo</th> */}
                <th colSpan="3" className={classes.th}>Variação</th>
                <th colSpan="2" className={classes.th}>Preço</th>
                <th colSpan="2" className={classes.th}>Valor</th>
                <th rowSpan="2" className={classes.th}>%<br />Geral</th>
                <th colSpan="2" className={classes.th}>Rentabilidade</th>
                <th rowSpan="2" className={classes.th}>Operações</th>
              </tr>
              <tr>
                <th className={classes.th}>YOC</th>
                <th colSpan="2" className={classes.th}>12 Meses</th>
                {/* <th className={classes.th}>1 Mês</th> */}
                <th className={classes.th}>1 Dia</th>
                <th className={classes.th}>1 Mês</th>
                {/* <th className={classes.th}>2023</th> */}
                <th className={classes.th}>1 Ano</th>
                <th className={classes.th}>Médio</th>
                <th className={classes.th}>Atual</th>
                <th className={classes.th}>Investido</th>
                <th className={classes.th}>Atual</th>
                <th className={classes.th}>%</th>
                <th className={classes.th}>$</th>
              </tr>
            </thead>
            <tbody>
              {stocks.length === 0 && <tr>
                <td colSpan='20'>Nenhum ativo cadastrado.</td>
              </tr>
              }
              {
                stocks.map((stock) => <tr key={stock.id}>
                  <td className={classes.td}>{stock.cotas}</td>
                  <td className={classes.td + " text-center"}>
                    <span className="font-weight-bold">{stock.codigo}</span><br />
                    <a href={`${Constants.BASE_URL_EUA_STOCK_STATUSINVEST}${stock.codigo}`} target='_blank' rel="noreferrer" title='StatusInvest'>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>&nbsp;&nbsp;
                    <a href={`${Constants.BASE_URL_EUA_STOCK_YAHOOFINANCE}${stock.codigo}`} target='_blank' rel="noreferrer" title='Yahoo! Finance'>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>
                  </td>
                  <td className={classes.td}>{stock.setor}<br />{stock.subsetor}</td>
                  <td className={classes.td}>{percentageFormatter.format(stock.mL / 100)}</td>
                  <td className={classes.td}>{stock.payout}%</td>
                  <td className={classes.td + "" + (stock.pvp < 1 ? " text-success" : "")}>{stock.pvp.replace(".", ",")}</td>
                  <td className={classes.td + "" + (stock.yoc < stock.dy ? " text-danger" : "")}>{percentageFormatter.format(stock.yoc / 100)}</td>
                  <td className={classes.td}>{percentageFormatter.format(stock.dy / 100)}</td>
                  <td className={classes.td}>$ {stock.d.replace(".", ",")}</td>
                  <td className={classes.td + "" + (stock.vD < 0 ? " text-danger" : "")}>{percentageFormatter.format(stock.vD / 100)}</td>
                  <td className={classes.td + "" + (stock.vM < 0 ? " text-danger" : "")}>{percentageFormatter.format(stock.vM / 100)}</td>
                  {/* <td className={classes.td}>{stock.vYTD}%</td> */}
                  <td className={classes.td + "" + (stock.vY < 0 ? " text-danger" : "")}>{percentageFormatter.format(stock.vY / 100)}</td>
                  <td className={classes.td + (stock.price > stock.currentPrice ? " text-danger" : "")}>{brlFormatter.format(stock.price)}</td>
                  <td className={classes.td}>{brlFormatter.format(stock.currentPrice)}</td>
                  <td className={classes.td + (stock.valorInvestido > stock.valorAtual ? " text-danger" : "")}>{brlFormatter.format(stock.valorInvestido)}</td>
                  <td className={classes.td}>{brlFormatter.format(stock.valorAtual)}</td>
                  <td className={classes.td + "" + (stock.parcelaTotalGeral / total > 0.02 ? " text-danger" : "")}>{percentageFormatter.format(stock.parcelaTotalGeral / total)}</td>
                  <td className={classes.td + (stock.rentabilidadePercentual < 0 ? " text-danger" : "")}>{percentageFormatter.format(stock.rentabilidadePercentual)}</td>
                  <td className={classes.td + (stock.rentabilidadeAbsoluta < 0 ? " text-danger" : "")}>{brlFormatter.format(stock.rentabilidadeAbsoluta)}</td>
                  <td className={classes.td} style={{ textAlign: "center" }}>
                    <Link onClick={() => editHandler(stock.id)}><FontAwesomeIcon icon={faEdit} /></Link>&nbsp;&nbsp;&nbsp;
                    <Link onClick={() => deleteHandler(stock.id)}><FontAwesomeIcon icon={faTrash} /></Link>
                  </td>
                </tr>
                )
              }
            </tbody>
          </Table>
        </ScrollContainer>
      </>
    }
  </>
}

export default WalletStocksList;

import * as Constants from '../../constants';

import { useState, useEffect, useCallback } from 'react';

import classes from "./StocksList.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";

import Table from 'react-bootstrap/Table';

import ScrollContainer from '../../components/UI/ScrollContainer';
import Card from '../../components/UI/Card';

import Loading from '../../components/UI/Loading';

const brlFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const percentageFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

function ETFsList(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [etfs, setETFs] = useState([]);

  const fetchETFsHandler = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(Constants.PUBLIC_ETFS_LIST);
      if (!response.ok) {
        alert('deu pau');
        throw new Error('Something went wrong!');
      }

      const data = await response.json();

      const loadedETFs = [];
      for (let item in data) {
        console.log(item);
        const element = data[item];
        let etf = {
          id: element.id,
          codigo: element.title,
          ticker: element.ticker,
          setor: element.sector,
          subsector: element.subsector,
          mL: element.mL,
          pvp: element.pvp,
          dy: element.dy,
          vD: element.vD,
          vM: element.vM,
          vY: element.vY,
          price: element.currentPrice,
        }
        loadedETFs.push(etf);
      }
      setETFs(loadedETFs);
    } catch (error) {
    }
    setIsLoading(false);
  }, [etfs]);

  useEffect(() => {
    fetchETFsHandler();
  }, []);

  useEffect(() => {
    if (etfs.length > 0)
      setIsLoading(false);
  }, [etfs]);

  return <>
    {isLoading && <Loading />}
    {!isLoading &&
      <Card>
        <div className='row'>
          <div className='col col-md-4'>

          </div>

          <div className='col col-md-2'>
            {/* <span className="fw-bold">Total Geral:</span> {isNaN(total) ? 0 : brlFormatter.format(total)} */}
          </div>

          <div className='col col-md-2'>
            {/* <span className="fw-bold">Total Investido em Ações:</span> {brlFormatter.format(totalInvestidoStocks)} */}
          </div>

          <div className='col col-md-2'>
            {/* <span className="fw-bold">Total em Ações:</span> {brlFormatter.format(totalStocks)} <small>({isNaN(totalStocks) || totalStocks === 0 ? '0%' : percentageFormatter.format(totalStocks / total)})</small> */}
          </div>

          <div className="col col-md-2">
            {/* <span className={(rentabilidadeTotal >= 0 ? 'fw-bold positive' : 'fw-bold negative')}>Rentabilidade:</span> {brlFormatter.format(rentabilidadeTotal)} <small>({isNaN(rentabilidadeTotal) || rentabilidadeTotal === 0 ? '0%' : percentageFormatter.format(rentabilidadeTotal / totalStocks)})</small> */}
          </div>
        </div>

        <ScrollContainer>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th rowSpan="2" className={classes.th}>Código</th>
                <th rowSpan="2" className={classes.th}>Setor<br />Subsetor</th>
                <th rowSpan="2" className={classes.th}>Margem<br />Líquida</th>
                <th rowSpan="2" className={classes.th}>Dívida<br />Líquida<br />/PL</th>
                {/* <th rowSpan="2" className={classes.th}>PVP</th> */}
                <th colSpan="1" rowSpan="2" className={classes.th}>Dividendos</th>
                {/* <th rowSpan="2" className={classes.th}>Dividend<br />Yield</th> */}
                {/* <th rowSpan="2" className={classes.th}>Último<br />Dividendo</th> */}
                <th colSpan="3" className={classes.th}>Variação</th>
                <th colSpan="1" rowSpan="2" className={classes.th}>Preço</th>
              </tr>
              <tr>
                {/* <th className={classes.th}>YOC</th> */}
                {/* <th colSpan="2" className={classes.th}>12 Meses</th> */}
                {/* <th className={classes.th}>1 Mês</th> */}
                <th className={classes.th}>1 Dia</th>
                <th className={classes.th}>1 Mês</th>
                <th className={classes.th}>1 Ano</th>
              </tr>
            </thead>
            <tbody>
              {etfs.length === 0 && <tr>
                <td colSpan='20'>Nenhum ativo cadastrado.</td>
              </tr>
              }
              {
                etfs.map((etf) => <tr key={etf.id}>
                  <td className={classes.td + " text-center"}>
                    <span className="font-weight-bolder">{etf.ticker}</span><br />
                    <a href={`${Constants.BASE_URL_EUA_STOCK_STATUSINVEST}${etf.ticker}`} target='_blank' rel="noreferrer" title='StatusInvest'>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>&nbsp;&nbsp;
                    <a href={`${Constants.BASE_URL_EUA_STOCK_YAHOOFINANCE}${etf.ticker}`} target='_blank' rel="noreferrer" title='Yahoo! Finance'>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>&nbsp;&nbsp;
                    <a href={`${Constants.BASE_URL_EUA_STOCKS_INVESTIDOR10}${etf.ticker}`} target='_blank' rel="noreferrer" title='Investidor10'>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>
                  </td>
                  <td className={classes.td}>{etf.setor}<br />{etf.subsector}</td>
                  <td className={classes.td}>{percentageFormatter.format(etf.mL / 100)}</td>
                  <td className={classes.td}>divida </td>
                  {/* <td className={classes.td}>{acao.payout}%</td> */}
                  {/* <td className={classes.td + "" + (acao.pvp < 1 ? " text-success" : "")}>{acao.pvp.replace(".", ",")}</td> */}
                  <td className={classes.td}>{percentageFormatter.format(etf.dy / 100)}</td>
                  <td className={classes.td + "" + (etf.vD < 0 ? " text-danger" : "")}>{percentageFormatter.format(etf.vD / 100)}</td>
                  <td className={classes.td + "" + (etf.vM < 0 ? " text-danger" : "")}>{percentageFormatter.format(etf.vM / 100)}</td>
                  {/* <td className={classes.td}>{acao.vYTD}%</td> */}
                  <td className={classes.td + "" + (etf.vY < 0 ? " text-danger" : "")}>{percentageFormatter.format(etf.vY / 100)}</td>
                  {/* <td className={classes.td + (acao.price > acao.currentPrice ? " text-danger" : "")}>{brlFormatter.format(acao.price)}</td> */}
                  <td className={classes.td}>{brlFormatter.format(etf.price)}</td>
                  {/* <td className={classes.td + (acao.valorInvestido > acao.valorAtual ? " text-danger" : "")}>{brlFormatter.format(acao.valorInvestido)}</td> */}
                  {/* <td className={classes.td}>{brlFormatter.format(acao.valorAtual)}</td> */}
                </tr>
                )
              }
            </tbody>
          </Table>
        </ScrollContainer>
      </Card>
    }
  </>
}

export default ETFsList;

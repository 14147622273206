export const SERVER = "http://www.deusprovider.com.br/investidorlibertario/";
// export const SERVER = "http://investidorlibertario.unaux.com/";
// export const SERVER = "http://investidorlibertario.free.nf/";
// export const SERVER = "http://localhost/provider2/"

export const LOGIN = SERVER + 'api/auth/login.php';

export const DELETE_ASSET = SERVER + 'api/wallet/delete-json-asset.php';
export const DELETE_WALLET = SERVER + 'api/wallet/delete-wallet-json-asset.php';
export const ADD_ASSET_LIST = SERVER + 'api/wallet/add-json-asset-list.php';

export const ADD_ASSET = SERVER + 'api/wallet/add-json-asset.php';
export const UPDATE_ASSET = SERVER + 'api/wallet/update-json-asset.php';

export const PUBLIC_ACOES_LIST = SERVER + 'api/assets/acoes/list.php';
export const PUBLIC_FIIS_LIST = SERVER + 'api/assets/fiis/list.php';
export const PUBLIC_STOCKS_LIST = SERVER + 'api/assets/stocks/list.php';
export const PUBLIC_ETFS_LIST = SERVER + 'api/assets/etfs/list.php';

export const WALLET_ACOES_LIST = SERVER + 'api/wallet/bra/acoes/list.php';
export const WALLET_FIIS_LIST = SERVER + 'api/wallet/bra/fiis/list.php';
export const WALLET_STOCKS_LIST = SERVER + 'api/wallet/eua/stocks/list.php';
export const WALLET_ETFS_LIST = SERVER + 'api/wallet/eua/etfs/list.php';

export const ACOES_LIST = SERVER + 'api/assets/acoes/list.php';
export const FIIS_LIST = SERVER + 'api/assets/fiis/list.php';
export const STOCKS_LIST = SERVER + 'api/assets/stocks/list.php';
export const ETFS_LIST = SERVER + 'api/assets/etfs/list.php';

export const PUBLIC_ACOES_LAST_UPDATE = SERVER + 'api/assets/acoes/last-update.php';

// ------------------------------------------------------------------------------
// EXTERNAL LINKS

export const BASE_URL_BRA_ACAO_STATUSINVEST = 'https://statusinvest.com.br/acoes/';
export const BASE_URL_EUA_STOCK_STATUSINVEST = 'https://statusinvest.com.br/acoes/eua/';
export const BASE_URL_EUA_ETF_STATUSINVEST = 'https://statusinvest.com.br/etf/eua/';

export const BASE_URL_BRA_ACAO_INFOMONEY = 'https://www.infomoney.com.br/';
export const BASE_URL_BRA_FII_INFOMONEY = 'https://www.infomoney.com.br/';

export const BASE_URL_BRA_FII_FUNDSEXPLORER = 'https://www.fundsexplorer.com.br/funds/';

export const BASE_URL_EUA_STOCK_YAHOOFINANCE = 'https://finance.yahoo.com/quote/';
export const BASE_URL_EUA_ETF_YAHOOFINANCE = 'https://finance.yahoo.com/quote/';

export const BASE_URL_BRA_ACAO_INVESTIDOR10 = 'https://investidor10.com.br/acoes/';
export const BASE_URL_BRA_FII_INVESTIDOR10 = 'https://investidor10.com.br/fiis/';
export const BASE_URL_EUA_STOCKS_INVESTIDOR10 = 'https://investidor10.com.br/stocks/';

export const BASE_URL_EUA_ETF_ETFDB = 'https://etfdb.com/etf/';
import { Outlet } from "react-router-dom";
import MainNavigation from "../components/MainNavigation";

function RootLayout(props) {
    return <>
        <MainNavigation isLoggedIn={props.isLoggedIn} onLogout={props.onLogout} />
        <div style={{ margin: "0.5rem" }}>
            <Outlet />
        </div>
    </>
}

export default RootLayout;
import * as Constants from '../constants';

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from '../components/UI/Card'
import WalletAcoesList from './wallet/WalletAcoesList';
import WalletFIIsList from './wallet/WalletFIIsList';
import WalletStocksList from './wallet/WalletStocksList';
import WalletEtfsList from './wallet/WalletEtfsList';

import Flag from 'react-world-flags'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

import Loading from '../components/UI/Loading';
import WalletSummary from './wallet/WalletSummary';

function WalletPage() {
    const dispatch = useDispatch();

    const [key, setKey] = useState('resumo');
    const [isLoading, setIsLoading] = useState(true);

    const [acoesList, setAcoesList] = useState([]);
    const [totalAcoes, setTotalAcoes] = useState(0);

    const [fiiList, setFiiList] = useState([]);
    const [totalFiis, setTotalFiis] = useState(0);

    const [stockList, setStockList] = useState([]);
    const [totalStocks, setTotalStocks] = useState(0);

    const [etfList, setEtfList] = useState([]);
    const [totalETFs, settotalETFs] = useState(0);    

    const resetHandler = () => {
        dispatch({ type: 'reset' });
    };

    const sumHandler = () => {
        dispatch({ type: 'sum', value: [totalAcoes, totalFiis, totalStocks, totalETFs] });
    };

    const updateAcoesHandler = async (acoes) => {
        setTotalAcoes(acoes);
        dispatch({ type: 'setTotalAcoes', value: acoes });
    };

    const updateFiisHandler = async (fiis) => {
        setTotalFiis(fiis);
        dispatch({ type: 'setTotalFIIs', value: fiis });
    };

    const updateStocksHandler = async (stocks) => {
        setTotalStocks(stocks);
        dispatch({ type: 'setTotalStocks', value: stocks });
    };

    const updateEtfsHandler = async (etfs) => {
        settotalETFs(etfs);
        dispatch({ type: 'settotalETFs', value: etfs });
    };    

    const fetchAcoesListHandler = useCallback(async () => {
        setIsLoading(true);
        // setError(null);
        try {
            const response = await fetch(Constants.ACOES_LIST);
            if (!response.ok) {
                throw new Error('Something went wrong!');
            }
            const data = await response.json();
            setIsLoading(false);
            setAcoesList(data);
        } catch (error) {
            // setError(error.message);
        }
    }, []);

    const fetchFiisListHandler = useCallback(async () => {
        setIsLoading(true);
        // setError(null);
        try {
            const response = await fetch(Constants.FIIS_LIST);
            if (!response.ok) {
                throw new Error('Something went wrong!');
            }
            const data = await response.json();
            setIsLoading(false);
            setFiiList(data);
        } catch (error) {
            // setError(error.message);
        }
    }, []);

    const fetchStocksListHandler = useCallback(async () => {
        setIsLoading(true);
        // setError(null);
        try {
            const response = await fetch(Constants.STOCKS_LIST);
            if (!response.ok) {
                throw new Error('Something went wrong!');
            }
            const data = await response.json();
            setIsLoading(false);
            setStockList(data);
        } catch (error) {
            // setError(error.message);
        }
    }, []);

    const fetchETFsListHandler = useCallback(async () => {
        setIsLoading(true);
        // setError(null);
        try {
            const response = await fetch(Constants.ETFS_LIST);
            if (!response.ok) {
                throw new Error('Something went wrong!');
            }
            const data = await response.json();
            setIsLoading(false);
            setEtfList(data);
        } catch (error) {
            // setError(error.message);
        }
    }, []);    

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            await fetchAcoesListHandler();
            await fetchFiisListHandler();
            await fetchStocksListHandler();
            await fetchETFsListHandler();
            setIsLoading(false);
        }
        fetchData();
    }, []);

    useEffect(() => {
        resetHandler();
    }, []);

    useEffect(() => {
        sumHandler();
    }, [totalAcoes, totalFiis, totalStocks, totalETFs]);

    const resumoTitle = <><FontAwesomeIcon icon={faChartLine} /> Resumo</>;
    const acoesTitle = <><Flag code="bra" height="15" /> Ações</>;
    const fiisTitle = <><Flag code="bra" height="15" /> FIIs&nbsp;</>;
    const stocksTitle = <><Flag code="usa" height="13" /> Stocks</>;
    const etfsTitle = <><Flag code="usa" height="13" /> ETFs</>;
    const reitsTitle = <><Flag code="usa" height="13" /> REITs</>;

    return <>
        {isLoading && <Loading />}
        {!isLoading && <>
            <div className="row">
                <div className="col col-md-12">
                    <Card>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="resumo" title={resumoTitle}>
                                <WalletSummary />
                            </Tab>
                            <Tab eventKey="acoes" title={acoesTitle}>
                                <WalletAcoesList assets={acoesList} onUpdateAssets={updateAcoesHandler} isLoading={isLoading} setIsLoading={setIsLoading} />
                            </Tab>
                            <Tab eventKey="fiis" title={fiisTitle}>
                                <WalletFIIsList assets={fiiList} onUpdateAssets={updateFiisHandler} isLoading={isLoading} setIsLoading={setIsLoading} />
                            </Tab>
                            <Tab eventKey="stocks" title={stocksTitle}>
                                <WalletStocksList assets={stockList} onUpdateAssets={updateStocksHandler} isLoading={isLoading} setIsLoading={setIsLoading} />
                            </Tab>
                            <Tab eventKey="etfs" title={etfsTitle}>
                                <WalletEtfsList assets={etfList} onUpdateAssets={updateEtfsHandler} isLoading={isLoading} setIsLoading={setIsLoading} />
                            </Tab>
                            <Tab eventKey="reits" title={reitsTitle}>
                                Em breve.
                            </Tab>
                            <Tab eventKey="contact" title="Contact" disabled>
                                Em breve.
                            </Tab>
                        </Tabs>
                    </Card>
                </div>
            </div>
        </>}
    </>

}

export default WalletPage;
import React, { useState, useEffect } from 'react';
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';

import './App.css';
import RootLayout from './pages/Root';
import HomePage from './pages/Home';
import WalletPage from './pages/Wallet';
import LoginPage from './pages/Login';
import AcoesList from './pages/public/AcoesList';
import FIIsList from './pages/public/FIIsList';
import StocksList from './pages/public/StocksList';
import ETFsList from './pages/public/ETFsList';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [userData, setUserData] = useState(null);

  const loginHandler = (data) => {
    try {
      localStorage.setItem('token', data.token);
      localStorage.setItem('avgDollar', data.avgDollar);
      setIsLoggedIn(true);
      redirect("/");
    } catch (error) {

    }
  }

  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("avgDollar");
    setIsLoggedIn(false);
  }

  const checkAuthLoader = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return redirect('/login');
    }
    return null;
  }

  const router = createBrowserRouter([
    {
      path: '/', element: <RootLayout isLoggedIn={isLoggedIn} onLogout={logoutHandler} />, children: [
        { path: '/', element: <HomePage /> },
        { path: '/acoes', element: <AcoesList /> },
        { path: '/fiis', element: <FIIsList /> },
        { path: '/stocks', element: <StocksList /> },
        { path: '/etfs', element: <ETFsList /> },
        { path: '/wallet', element: <WalletPage />, loader: checkAuthLoader },
        { path: '/login', element: <LoginPage onLogin={loginHandler} /> }
      ]
    },
  ]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      loginHandler();
    }
  }, []);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;

import React, { useRef } from 'react';

const ScrollContainer = ({ children }) => {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const windowHeight = Math.round(windowSize.current[1] * 0.7395 ); 

    return (
        <div style={{ overflowY: 'scroll', height: windowHeight+"px" }}>
            {children}
        </div>
    );
};

export default ScrollContainer;
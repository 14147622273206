import * as Constants from '../../constants';

import { useState, useEffect, useCallback } from 'react';

import classes from "./AcoesList.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import ScrollContainer from '../../components/UI/ScrollContainer';
import Card from '../../components/UI/Card';

import Loading from '../../components/UI/Loading';
import AssetFilterForm from '../../components/AssetFilterForm';


const brlFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const percentageFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const defaultFormAsset = { id: 0, ticker: 0, quotas: 0, price: 0 };

function AcoesList(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [acoes, setAcoes] = useState([]);
  const [filterFormIsShown, setFilterFormIsShown] = useState(false);
  const [lastUpdate, setLastUpdate] = useState("");

  const fetchAcoesHandler = useCallback(async () => {
    setIsLoading(true);
    try {
      const lastUpdateReponse = await fetch(Constants.PUBLIC_ACOES_LAST_UPDATE);
      const lastUpdateData = await lastUpdateReponse.json();
      setLastUpdate(lastUpdateData.created);

      const response = await fetch(Constants.PUBLIC_ACOES_LIST);
      if (!response.ok) {
        alert('deu pau');
        throw new Error('Something went wrong!');
      }

      const data = await response.json();
      // console.log(data);
      const loadedAcoes = [];
      this.lastUpdate = new Date();

      for (let item in data) {
        const i = data[item];
        let acao = {
          id: i.id,
          codigo: i.ticker,
          ticker: i.ticker,
          setor: i.sector,
          subsector: i.subsector,
          mL: i.mL,
          pvp: i.pvp,
          dy: i.dy,
          vD: i.vD,
          vM: i.vM,
          vY: i.vY,
          price: i.currentPrice,
        }
        // console.log(acao);
        loadedAcoes.push(acao);
      }
      setAcoes(loadedAcoes);

      // console.log(acoes);
    } catch (error) {
    }
    setIsLoading(false);
  }, [acoes]);

  useEffect(() => {
    fetchAcoesHandler();
  }, []);

  useEffect(() => {
    if (acoes.length > 0)
      setIsLoading(false);
  }, [acoes]);

  const showHideFilterFormHandler = () => {
    setFilterFormIsShown(!filterFormIsShown);
  };

  const dataAtualFormatada = (dt) => {
    var data = new Date(dt);
    var dia = data.getDate().toString(),
      diaF = (dia.length === 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length === 1) ? '0' + mes : mes,
      anoF = data.getFullYear(),
      hora = (data.getHours().toString().length === 1) ? '0' + data.getHours() : data.getHours(),
      min = (data.getMinutes().toString().length === 1) ? '0' + data.getMinutes() : data.getMinutes(),
      seg = (data.getSeconds().toString().length === 1) ? '0' + data.getSeconds() : data.getSeconds();
      
    return diaF + "/" + mesF + "/" + anoF + " " + hora + ":" + min + ":" + seg;
  }

  return <>
    {isLoading && <Loading />}
    {!isLoading &&
      <Card>
        <div className='row'>
          <div className='col col-md-4'>

          </div>

          <div className='col col-md-2'>
            {/* <span className="fw-bold">Total Geral:</span> {isNaN(total) ? 0 : brlFormatter.format(total)} */}
          </div>

          <div className='col col-md-2'>
            {/* <span className="fw-bold">Total Investido em Ações:</span> {brlFormatter.format(totalInvestidoAcoes)} */}
          </div>

          <div className='col col-md-2'>
            {/* <span className="fw-bold">Total em Ações:</span> {brlFormatter.format(totalAcoes)} <small>({isNaN(totalAcoes) || totalAcoes === 0 ? '0%' : percentageFormatter.format(totalAcoes / total)})</small> */}
          </div>

          <div className="col col-md-2">
            {/* <span className={(rentabilidadeTotal >= 0 ? 'fw-bold positive' : 'fw-bold negative')}>Rentabilidade:</span> {brlFormatter.format(rentabilidadeTotal)} <small>({isNaN(rentabilidadeTotal) || rentabilidadeTotal === 0 ? '0%' : percentageFormatter.format(rentabilidadeTotal / totalAcoes)})</small> */}
          </div>
        </div>

        {filterFormIsShown && <AssetFilterForm
        // method="POST"
        // onFinishSubmit={finishSubmitAssetHandler}
        // onClose={hideFormHandler}
        />}
        <div className='row'>
          <div className='col'>
            {!filterFormIsShown && <Button variant="secondary" className={classes.filterButton} onClick={showHideFilterFormHandler}><FontAwesomeIcon icon={faFilter} /></Button>}
          </div>
          <div className='col'></div>
          <div className='col-2'>
            <b>Última atualização:</b>&nbsp;&nbsp;<small>{dataAtualFormatada(lastUpdate)}</small>
          </div>
        </div>

        <ScrollContainer>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th rowSpan="2" className={classes.th}>Código</th>
                <th rowSpan="2" className={classes.th}>Setor<br />Subsetor</th>
                <th rowSpan="2" className={classes.th}>Margem<br />Líquida</th>
                <th rowSpan="2" className={classes.th}>Dívida<br />Líquida<br />/PL</th>
                <th rowSpan="2" className={classes.th}>PVP</th>
                <th colSpan="1" rowSpan="2" className={classes.th}>Dividendos</th>
                {/* <th rowSpan="2" className={classes.th}>Dividend<br />Yield</th> */}
                {/* <th rowSpan="2" className={classes.th}>Último<br />Dividendo</th> */}
                <th colSpan="3" className={classes.th}>Variação</th>
                <th colSpan="1" rowSpan="2" className={classes.th}>Preço</th>
              </tr>
              <tr>
                {/* <th className={classes.th}>YOC</th> */}
                {/* <th colSpan="2" className={classes.th}>12 Meses</th> */}
                {/* <th className={classes.th}>1 Mês</th> */}
                <th className={classes.th}>1 Dia</th>
                <th className={classes.th}>1 Mês</th>
                <th className={classes.th}>1 Ano</th>
              </tr>
            </thead>
            <tbody>
              {acoes.length === 0 && <tr>
                <td colSpan='20'>Nenhum ativo cadastrado.</td>
              </tr>
              }
              {
                acoes.map((acao) => <tr key={acao.id}>
                  <td className={classes.td + " text-center"}>
                    <span className="font-weight-bolder">{acao.codigo}</span><br />
                    <a href={`${Constants.BASE_URL_BRA_ACAO_STATUSINVEST}${acao.codigo}`} target='_blank' rel="noreferrer" title='StatusInvest'>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>&nbsp;&nbsp;
                    <a href={`${Constants.BASE_URL_BRA_ACAO_INFOMONEY}${acao.codigo}`} target='_blank' rel="noreferrer" title='InfoMoney'>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>&nbsp;&nbsp;
                    <a href={`${Constants.BASE_URL_BRA_ACAO_INVESTIDOR10}${acao.codigo}`} target='_blank' rel="noreferrer" title='Investidor10'>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>
                  </td>
                  <td className={classes.td}>{acao.setor}<br />{acao.subsector}</td>
                  <td className={classes.td}>{percentageFormatter.format(acao.mL / 100)}</td>
                  <td className={classes.td}>divida </td>
                  <td className={classes.td + "" + (acao.pvp < 1 ? " text-success" : "")}>{acao.pvp}</td>
                  <td className={classes.td}>{percentageFormatter.format(acao.dy / 100)}</td>
                  <td className={classes.td + "" + (acao.vD < 0 ? " text-danger" : "")}>{percentageFormatter.format(acao.vD / 100)}</td>
                  <td className={classes.td + "" + (acao.vM < 0 ? " text-danger" : "")}>{percentageFormatter.format(acao.vM / 100)}</td>
                  <td className={classes.td + "" + (acao.vY < 0 ? " text-danger" : "")}>{percentageFormatter.format(acao.vY / 100)}</td>
                  <td className={classes.td}>{brlFormatter.format(acao.price)}</td>
                </tr>
                )
              }
            </tbody>
          </Table>
        </ScrollContainer>
      </Card>
    }
  </>
}

export default AcoesList;

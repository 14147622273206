import * as Constants from '../constants';

import { useState } from 'react';

import Modal from "./UI/Modal";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

import classes from "./AssetFilterForm.module.css"

const AssetFilterForm = (props) => {
    const [ticker, setTicker] = useState("");
    const [sector, setSector] = useState("");
    const [subSector, setSubSector] = useState("");
    const [mL, setML] = useState(0);
    const [divida, setDivida] = useState(0);
    const [pvp, setPvp] = useState(0);
    const [dy, setDy] = useState(0);

    const [vD, setVD] = useState(0);
    const [vM, setVM] = useState(0);
    const [vY, setVY] = useState(0);
    const [price, setPrice] = useState(0);

    const addURL = Constants.ADD_ASSET;
    const editURL = Constants.UPDATE_ASSET;

    const changeTickerHandler = (value) => {
        setTicker(value);
    };

    const changeSectorHandler = (value) => {
        setSector(value);
    };

    const changeSubSectorHandler = (value) => {
        setSubSector(value);
    };

    const changeMargemHandler = (value) => {
        setML(value);
    };

    const changePvpHandler = (value) => {
        setPvp(value);
    };

    const changeDividaHandler = (value) => {
        setDivida(value);
    };

    const changeDividendYieldHandler = (value) => {
        setDy(value);
    };

    const changeVariacao1DHandler = (value) => {
        setVD(value);
    };

    const changeVariacao1MHandler = (value) => {
        setVM(value);
    };

    const changeVariacao1YHandler = (value) => {
        setVY(value);
    };

    const changePriceHandler = (value) => {
        setPrice(value);
    };

    const submitAssetHandler = async (event) => {
        event.preventDefault();
        // console.log(props.edit, tickerId);

        const assetData = {
            ticker,
            sector,
            subSector,
            mL,
            divida,
            pvp,
            dy,
            vD,
            vM,
            vY,
            price
        };

        const url = (props.edit ? editURL : addURL);
        const response = await fetch(url, {
            method: props.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(assetData)
        });
        props.onFinishSubmit(assetData);
    };

    return (
        <Modal modalClass={classes.modal} onClose={props.onClose}>
            <Form onSubmit={submitAssetHandler}>
                <Container><br />
                    <Row>
                        <Col xs={4}>
                            <Form.Label className="fw-bold pt-1">Ticker:</Form.Label>
                            <Form.Group className="mb-3" controlId="ticker">
                                <Form.Control
                                    type="text"
                                    value={ticker}
                                    onChange={(e) => changeTickerHandler(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={4}>
                            <Form.Label className="fw-bold pt-1">Setor:</Form.Label>
                            <Form.Group className="mb-3" controlId="sector">
                                <Form.Control
                                    type="text"
                                    value={sector}
                                    onChange={(e) => changeSectorHandler(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={4}>
                            <Form.Label className="fw-bold pt-1">Sub-Setor:</Form.Label>
                            <Form.Group className="mb-3" controlId="subsector">
                                <Form.Control
                                    type="text"
                                    value={subSector}
                                    onChange={(e) => changeSubSectorHandler(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            <Form.Label className="fw-bold pt-1">Margem:</Form.Label>
                            <Form.Select as="select" aria-label="Default select example">
                                <option value={1}>{'<'}&nbsp;&nbsp;&nbsp;Menor</option>
                                <option value={2}>{'<='}&nbsp;&nbsp;Menor ou igual</option>
                                <option value={3}>{'='}&nbsp;&nbsp;&nbsp;Igual</option>
                                <option value={4}>{'>'}&nbsp;&nbsp;&nbsp;Maior</option>
                                <option value={5}>{'>='}&nbsp;&nbsp;Maior ou igual</option>
                            </Form.Select>
                            <Form.Group className="mb-3" controlId="formBasicMessage">
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    value={mL}
                                    onChange={(e) => changeMargemHandler(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={3}>
                            <Form.Label className="fw-bold pt-1">Dívida:</Form.Label>
                            <Form.Select as="select" aria-label="Default select example">
                                <option value={1}>{'<'}&nbsp;&nbsp;&nbsp;Menor</option>
                                <option value={2}>{'<='}&nbsp;&nbsp;Menor ou igual</option>
                                <option value={3}>{'='}&nbsp;&nbsp;&nbsp;Igual</option>
                                <option value={4}>{'>'}&nbsp;&nbsp;&nbsp;Maior</option>
                                <option value={5}>{'>='}&nbsp;&nbsp;Maior ou igual</option>
                            </Form.Select>
                            <Form.Group className="mb-3" controlId="formBasicMessage">
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    value={divida}
                                    onChange={(e) => changeDividaHandler(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={3}>
                            <Form.Label className="fw-bold pt-1">P/VP:</Form.Label>
                            <Form.Select as="select" aria-label="Default select example">
                                <option value={1}>{'<'}&nbsp;&nbsp;&nbsp;Menor</option>
                                <option value={2}>{'<='}&nbsp;&nbsp;Menor ou igual</option>
                                <option value={3}>{'='}&nbsp;&nbsp;&nbsp;Igual</option>
                                <option value={4}>{'>'}&nbsp;&nbsp;&nbsp;Maior</option>
                                <option value={5}>{'>='}&nbsp;&nbsp;Maior ou igual</option>
                            </Form.Select>
                            <Form.Group className="mb-3" controlId="formBasicMessage">
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    value={pvp}
                                    onChange={(e) => changePvpHandler(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={3}>
                            <Form.Label className="fw-bold pt-1">Dividend Yield:</Form.Label>
                            <Form.Select as="select" aria-label="Default select example">
                                <option value={1}>{'<'}&nbsp;&nbsp;&nbsp;Menor</option>
                                <option value={2}>{'<='}&nbsp;&nbsp;Menor ou igual</option>
                                <option value={3}>{'='}&nbsp;&nbsp;&nbsp;Igual</option>
                                <option value={4}>{'>'}&nbsp;&nbsp;&nbsp;Maior</option>
                                <option value={5}>{'>='}&nbsp;&nbsp;Maior ou igual</option>
                            </Form.Select>
                            <Form.Group className="mb-3" controlId="formBasicMessage">
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    value={dy}
                                    onChange={(e) => changeDividendYieldHandler(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            <Form.Label className="fw-bold pt-1">Variação 1 Dia:</Form.Label>
                            <Form.Select as="select" aria-label="Default select example">
                                <option value={1}>{'<'}&nbsp;&nbsp;&nbsp;Menor</option>
                                <option value={2}>{'<='}&nbsp;&nbsp;Menor ou igual</option>
                                <option value={3}>{'='}&nbsp;&nbsp;&nbsp;Igual</option>
                                <option value={4}>{'>'}&nbsp;&nbsp;&nbsp;Maior</option>
                                <option value={5}>{'>='}&nbsp;&nbsp;Maior ou igual</option>
                            </Form.Select>
                            <Form.Group className="mb-3" controlId="formBasicMessage">
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    value={vD}
                                    onChange={(e) => changeVariacao1DHandler(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={3}>
                            <Form.Label className="fw-bold pt-1">Variação 1 Mês:</Form.Label>
                            <Form.Select as="select" aria-label="Default select example">
                                <option value={1}>{'<'}&nbsp;&nbsp;&nbsp;Menor</option>
                                <option value={2}>{'<='}&nbsp;&nbsp;Menor ou igual</option>
                                <option value={3}>{'='}&nbsp;&nbsp;&nbsp;Igual</option>
                                <option value={4}>{'>'}&nbsp;&nbsp;&nbsp;Maior</option>
                                <option value={5}>{'>='}&nbsp;&nbsp;Maior ou igual</option>
                            </Form.Select>
                            <Form.Group className="mb-3" controlId="formBasicMessage">
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    value={vM}
                                    onChange={(e) => changeVariacao1MHandler(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={3}>
                            <Form.Label className="fw-bold pt-1">Variação 1 Ano:</Form.Label>
                            <Form.Select as="select" aria-label="Default select example">
                                <option value={1}>{'<'}&nbsp;&nbsp;&nbsp;Menor</option>
                                <option value={2}>{'<='}&nbsp;&nbsp;Menor ou igual</option>
                                <option value={3}>{'='}&nbsp;&nbsp;&nbsp;Igual</option>
                                <option value={4}>{'>'}&nbsp;&nbsp;&nbsp;Maior</option>
                                <option value={5}>{'>='}&nbsp;&nbsp;Maior ou igual</option>
                            </Form.Select>
                            <Form.Group className="mb-3" controlId="formBasicMessage">
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    value={vY}
                                    onChange={(e) => changeVariacao1YHandler(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={3}>
                            <Form.Label className="fw-bold pt-1">Preço:</Form.Label>
                            <Form.Select as="select" className={classes.filterFormControl}>
                                <option value={1}>{'<'}&nbsp;&nbsp;&nbsp;Menor</option>
                                <option value={2}>{'<='}&nbsp;&nbsp;Menor ou igual</option>
                                <option value={3}>{'='}&nbsp;&nbsp;&nbsp;Igual</option>
                                <option value={4}>{'>'}&nbsp;&nbsp;&nbsp;Maior</option>
                                <option value={5}>{'>='}&nbsp;&nbsp;Maior ou igual</option>
                            </Form.Select>
                            <Form.Group className="mb-3" controlId="formBasicMessage">
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    value={sector}
                                    onChange={(e) => changeDividaHandler(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={2}>
                            <Button variant="primary" type="submit" className={classes.filterFormButton}>
                                <FontAwesomeIcon icon={faFloppyDisk} />&nbsp;Adicionar
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Form >
        </Modal >
    );
};

export default AssetFilterForm;
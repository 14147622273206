import * as Constants from '../../constants';

import { useState, useEffect, useCallback } from 'react';

import classes from "./FIIsList.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";

import Table from 'react-bootstrap/Table';

import ScrollContainer from '../../components/UI/ScrollContainer';
import Card from '../../components/UI/Card';

import Loading from '../../components/UI/Loading';

const brlFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const percentageFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

function FIIsList(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [FIIs, setFIIs] = useState([]);

  const fetchFIIsHandler = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(Constants.PUBLIC_FIIS_LIST);
      if (!response.ok) {
        alert('deu pau');
        throw new Error('Something went wrong!');
      }

      const data = await response.json();

      const loadedFIIs = [];
      for (let item in data) {
        const element = data[item];
        let fii = {
          id: element.id,
          codigo: element.title,
          ticker: element.ticker,
          setor: element.sector,
          subsector: element.subsector,
          mL: element.mL,
          pvp: element.pvp,
          dy: element.dy,
          vD: element.vD,
          vM: element.vM,
          vY: element.vY,
          price: element.currentPrice,
          rXP: element.rXP,
          xPoupanca: element.xPoupanca,
          d: element.d,
          dy1: element.dy1,
          dy3: element.dy3,
          dy6: element.dy6,
          dy12: element.dy12,
          dyIPO: element.dyIPO,

        }
        loadedFIIs.push(fii);
      }
      setFIIs(loadedFIIs);
    } catch (error) {
    }
    setIsLoading(false);
  }, [FIIs]);

  useEffect(() => {
    fetchFIIsHandler();
  }, []);

  useEffect(() => {
    if (FIIs.length > 0)
      setIsLoading(false);
  }, [FIIs]);

  return <>
    {isLoading && <Loading />}
    {!isLoading &&
      <Card>
        <div className='row'>
          <div className='col col-md-4'>

          </div>

          <div className='col col-md-2'>
            {/* <span className="fw-bold">Total Geral:</span> {isNaN(total) ? 0 : brlFormatter.format(total)} */}
          </div>

          <div className='col col-md-2'>
            {/* <span className="fw-bold">Total Investido em Ações:</span> {brlFormatter.format(totalInvestidoFIIs)} */}
          </div>

          <div className='col col-md-2'>
            {/* <span className="fw-bold">Total em Ações:</span> {brlFormatter.format(totalFIIs)} <small>({isNaN(totalFIIs) || totalFIIs === 0 ? '0%' : percentageFormatter.format(totalFIIs / total)})</small> */}
          </div>

          <div className="col col-md-2">
            {/* <span className={(rentabilidadeTotal >= 0 ? 'fw-bold positive' : 'fw-bold negative')}>Rentabilidade:</span> {brlFormatter.format(rentabilidadeTotal)} <small>({isNaN(rentabilidadeTotal) || rentabilidadeTotal === 0 ? '0%' : percentageFormatter.format(rentabilidadeTotal / totalFIIs)})</small> */}
          </div>
        </div>

        <ScrollContainer>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th rowSpan="2" className={classes.th}>Código</th>
                <th rowSpan="2" className={classes.th}>Setor<br />Segmento</th>
                <th rowSpan="2" className={classes.th}>Risco<br />XP</th>
                <th rowSpan="2" className={classes.th}>Rendimento<br /> x Poupança</th>
                <th rowSpan="2" className={classes.th}>PVP</th>
                <th colSpan="6" className={classes.th}>Dividend Yield</th>
                <th colSpan="3" className={classes.th}>Variação</th>
                <th colSpan="2" className={classes.th}>Preço</th>
              </tr>
              <tr>
                {/* <th className={classes.th}>Último</th> */}
                <th colSpan="2" className={classes.th}>1 Mês</th>
                <th className={classes.th}>3 Meses</th>
                <th className={classes.th}>6 Meses</th>
                <th className={classes.th}>12 Meses</th>
                <th className={classes.th}>IPO</th>
                <th className={classes.th}>1 Dia</th>
                <th className={classes.th}>1 Mês</th>
                <th className={classes.th}>1 Ano</th>
                <th className={classes.th}>Atual</th>
              </tr>
            </thead>
            <tbody>
              {FIIs.length === 0 && <tr>
                <td colSpan='24'>Nenhum ativo cadastrado.</td>
              </tr>
              }
              {
                FIIs.map((fii) => <tr key={fii.id}>
                  <td className={classes.td + " text-center"}>
                    <span className={"font-weight-bold"}>{fii.ticker}</span><br />
                    <a href={`${Constants.BASE_URL_BRA_FII_FUNDSEXPLORER}${fii.ticker}`} target='_blank' rel="noreferrer" title='FundsExplorer'>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>&nbsp;&nbsp;
                    <a href={`${Constants.BASE_URL_BRA_FII_INFOMONEY}${fii.ticker}`} target='_blank' rel="noreferrer" title='InfoMoney'>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>
                  </td>
                  <td className={classes.td}>{fii.setor}<br />{fii.subsetor}</td>
                  <td className={classes.td + " text-center"}>{fii.rXP}</td>
                  <td className={classes.td + "" + (fii.xPoupanca < 0 ? " text-danger" : "")}>{percentageFormatter.format(fii.xPoupanca / 100)}</td>
                  <td className={classes.td + "" + (fii.pvp < 1 ? " text-success" : "")}>{fii.pvp.replace(".", ",")}</td>
                  <td className={classes.td}>{percentageFormatter.format(fii.dy1 / 100)}</td>
                  <td className={classes.td}>R$ {fii.d.replace(".", ",")}</td>
                  <td className={classes.td}>{percentageFormatter.format(fii.dy3 / 100)}</td>
                  <td className={classes.td}>{percentageFormatter.format(fii.dy6 / 100)}</td>
                  <td className={classes.td}>{percentageFormatter.format(fii.dy12 / 100)}</td>
                  <td className={classes.td}>{percentageFormatter.format(fii.dyIPO / 100)}</td>
                  <td className={classes.td + "" + (fii.vD < 0 ? " text-danger" : "")}>{percentageFormatter.format(fii.vD / 100)}</td>
                  <td className={classes.td + "" + (fii.vM < 0 ? " text-danger" : "")}>{percentageFormatter.format(fii.vM / 100)}</td>
                  <td className={classes.td + "" + (fii.vY < 0 ? " text-danger" : "")}>{percentageFormatter.format(fii.vY / 100)}</td>
                  <td className={classes.td + "" + (fii.price < fii.currentPrice ? " text-danger" : "")}>{brlFormatter.format(fii.price)}</td>
                </tr>
                )
              }
            </tbody>
          </Table>
        </ScrollContainer>
      </Card>
    }
  </>
}

export default FIIsList;

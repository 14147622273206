import Modal from './Modal';

import classes from './Loading.module.css';

const Loading = (props) => {
    return <Modal modalClass={classes.modal} onClose={props.onClose}>
        <img src="img/preloader.gif" alt="Loading..." />
    </Modal>
};

export default Loading;